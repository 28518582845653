import { Box, Typography } from "@mui/material"
import React from "react"
import headerPhoto from "../assets/contraversy.jpeg"
import links from "../links.json"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"

const Home = () => {
  const storyText = { fontWeight: 600, fontSize: "1.5rem" }
  return (
    <Box
      sx={{
        backgroundColor: "grey",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      }}
    >
      <img
        src={headerPhoto}
        alt="poster depicting the exposé"
        width={"100%"}
        height={"auto"}
      />
      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "3.0rem",
            fontWeight: 900,
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
          }}
        >
          Discord in Highland Park: The Battle of Ravinia and the Politics of
          Power
        </Typography>
        <Typography sx={{ ...storyText }}>
          In the picturesque town of Highland Park, a tempest brews—a conflict
          laden with ambition, power, and discord. Central to this storm is the
          Ravinia Music Festival, a cultural titan clashing with the humbler
          Ravinia Brewery. But the discord spirals beyond business rivalries,
          entangling local politics and community leaders in a narrative of
          confrontation and controversy.
        </Typography>
        <Typography sx={{ ...storyText }}>
          The Ravinia Music Festival, despite its nonprofit status and cultural
          acclaim, has become embroiled in a legal squabble with the much
          smaller Ravinia Brewery. The disparity in resources is stark: the
          Festival, holding assets worth over a quarter billion dollars, pays
          minimal taxes due to its nonprofit status, yet it wields significant
          financial power—a Goliath to the brewery's David. This legal battle,
          observers argue, isn't about winning in the strictest sense but about
          outspending, leveraging vast resources to bear on a smaller opponent
          in a display of might rather than right.
        </Typography>
        <Typography sx={{ ...storyText }}>
          Adding complexity to this tale is the recent municipal election, where
          councilmember Jeff Hoobler, part-owner of Ravinia Brewery, secured a
          landslide victory despite lacking the endorsement of Highland Park
          Mayor Nancy Rotering. Mayor Rotering, who has both an{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://ravinia.org/Online/default.asp?BOparam::WScontent::loadArticle::permalink=Ravinia_Trustees&BOparam::WScontent::loadArticle::context_id="
          >
            Honorary Chair
          </a>{" "}
          with the Board of Ravinia and is an active member of the festival's{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://ravinia.org/Online/default.asp?BOparam::WScontent::loadArticle::permalink=Ravinia_WomensBoard&BOparam::WScontent::loadArticle::context_id="
          >
            woman's board
          </a>
          , is a figure with higher political aspirations. Yet she has seen her
          political fortunes wane, possibly fueling a bitter rivalry. Hoobler’s
          success not only underscores the Mayor’s political challenges but also
          casts a long shadow over her ambitions, suggesting his triumph and her
          setbacks are deeply intertwined.
        </Typography>
        <Typography sx={{ ...storyText }}>
          The plot thickens with the dismissal of Karen Ettelson from the
          festival’s woman's board. Rumors swirl that Ettelson, possibly in a
          moment of courage or solidarity, spoke in favor of Hoobler, urging the
          festival to abandon its aggressive legal posture towards the brewery.
          The lack of transparency around her departure leaves room for
          speculation that her dismissal was a consequence of her dissent,
          further painting a picture of an institution where dissent is not
          tolerated.
        </Typography>
        <Typography sx={{ ...storyText }}>
          Mayor Rotering’s role becomes even more controversial with the
          revelation of a Prohibition-era law. Just weeks after approving
          Hoobler’s liquor license renewal, a mysterious tip led to a
          reevaluation under this archaic regulation, which prohibits city
          council members from holding a liquor license. While neighboring
          suburbs have modernized such outdated laws, Mayor Rotering and her
          allies appear resolute in their enforcement, presenting Hoobler with
          an impossible choice: his council seat or his livelihood.
        </Typography>
        <Typography sx={{ ...storyText }}>
          This saga, with its rich tapestry of legal battles, political
          aspirations, and community impact, provides fertile ground for
          reflection. While the connections between the music festival's
          lawsuit, the mayor's political endeavors, and Ettelson’s dismissal are
          not explicitly confirmed, the interwoven narratives suggest a complex
          web of influence and retaliation at play. The reader is left to
          ponder: could these events, driven by individual ambitions and
          institutional power, be part of a broader, orchestrated strategy? The
          answer remains veiled, leaving a lingering question about the true
          cost of power and resistance in the heart of Highland Park.
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography sx={{ ...storyText, color: "white" }}>
          Related Links:
        </Typography>
        {links.map((link, index) => (
          <Box
            sx={index === 0 && { backgroundColor: "black", padding: "10px" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href={link.link}
              style={{
                textDecoration: "underline",
                textDecorationColor: "white",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ArrowForwardIosIcon color="action" />
              <Typography
                sx={{
                  ...storyText,
                  color: "inherit",
                  textDecorationColor: "inherit",
                }}
              >
                {link.title}
              </Typography>
            </a>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Home
